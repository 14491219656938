@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap");


*{
    margin: 0;
    padding: 0;
}



:root {
  --primary-white: #fff;
  --primary-orange: #ff845e;
  --primary-grey-blue: #595d6b;
}

/* navbar */

.navbar {
  box-shadow: 0px 4px 26px rgba(186, 186, 186, 0.2);
  height: 100px;
  /* max-height: 100px; */
 
}

.brand{
  font-weight: 600;
}

.Container{
  padding: 10px  5px;
}

.logo{
  height: 50;
}

.navText {
  font-weight: medium;
  /* font-size: 21px; */
  user-select: none;
}

.navText:hover {
  color: var(--primary-orange) !important;
}

.active {
  color: var(--primary-orange) !important;
}



@media (max-width: 986px) {
  nav {
    height: max-content !important;
  }
}

@media (max-width: 430px) {
  .container{
    box-sizing: border-box;
    padding: 12px;
    margin: 0;
  }
  nav{
    padding: 0 !important;
  }
 
}
