@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,700;0,900;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap');

* {
  font-family: 'Poppins',"Roboto", sans-serif;
  color: rgb(90, 89, 89);
}
.App {
  margin:0 ;
  
}