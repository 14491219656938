

#footer {
    margin-top: 20px;
    padding: 10px 20px;
    min-height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
 
  
  .logo {
    display: flex;
  }
  
  .company,
  .other-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .f-logo {
    max-width: 200px;
  }
  
  .f-container {
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }

  .copyright{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    gap:25px;
  }
  


  .f_links {
    text-decoration: none;
    color: white;
    margin-top: 5px;
    font-size: 19px;
  }
  
  .f_links:hover {
    color: white;
  }
  
  .social-media {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0px !important;
    /* padding-left: 1rem !important; */
  }
  
  .social-media-item {
    margin: 10px !important;
  }
  
  @media (max-width: 800px) {
    footer {
      margin-top: 50px !important;
    }
    .f-top {
      flex-direction: column;
    }
  
    .logo,
    .company,
    .other-links {
      width: 100%;
      margin-top: 30px;
      align-items: center;
      justify-content: center;
    }
  
    .f-container {
      max-width: 1250px;
      
      
      padding: 30px 30px 0 30px;
      box-sizing: border-box;
    }
  
    .copyright {
      margin: 20px auto;
    }
  }
  @media (max-width: 320px) {
    a > svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  
    .copyright {
      margin: 30px auto;
    }
  }
  