.main {
  padding: 10px 25px 0px 25px;
  width: 90vw;
  max-width: 1300px;
  margin: auto;
}

h2 {
  color: #ff845e;
  font-size: min(3.3vw, 35px);
  font-weight: bold !important;
  text-align: center;
  margin-top: min(3rem, 40px);
}

.section-1 {
  /* height: 80vh; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 3rem;
}

.section-1-0 {
  position: relative;
  width: 100%;
}

.pic-1 {
  display: block;
  filter: brightness(70%);
  width: 100%;
      height: auto;
}



.section-1-1 {
  width: 100%;
}

.containertop {
  position: relative;
  width: 100%;
}

.top-image {
  display: block;
  width: 100%;
  height: 85vh;
  filter: brightness(40%);
  object-fit: cover;
  z-index: 0;
}

.toptext {
  position: absolute;
  width: 80vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 700;
  font-size: 4em;
  text-shadow: 2px 2px 8px rgba(20, 20, 20,0.75);
  text-align: center;
  z-index: 1;
}

.transparent{
  color: transparent;
      -webkit-text-stroke: 1.5px white;
      text-shadow: none;

}


@media (max-width:900px){
  .toptext{
    font-size: 2.75em;
    -webkit-text-stroke: 1px white;
  }
  .top-image{
    /* height: 70vh; */
    height: auto;
  }

}





.section-1-1 h3 {
  color: #ff845e;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: min(4vw, 40px);
  margin: 6px 12px;
}

h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: min(6vw, 80px) !important;
  /* line-height: 112px; */
  color: rgb(20, 20, 20);
  margin: 16px 12px;
}

.section-1-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
}
.section-1-container span img {
  height: 6vw;
}

.arrow {
  filter: invert(100%);
}

.reg {
  display: flex;
  align-self: unset;
  font-size: min(2.5vw, 35px);
  flex-shrink: disable;
}

a {
  text-decoration: none !important;
}

.reg-con {
  display: flex;
  justify-content: end;
  align-items: center;
  align-self: baseline;
  position: relative;
}
.reg-con::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fa5a29;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.reg-con:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.reg-con img {
  width: 4vw;
}

.reg-con button {
  border: none;
  background: white;
}
.register {
  padding-right: 8px;
  text-align: right;
  margin: 0;
  display: inline-block;
}



@media (max-width: 500px) {
  .section-1 {
    height: auto;
  }
  .section-1-1 h3,
  .section-1-1 h1 {
    text-align: center;
  }
  .reg-con p {
    font-size: 12px;
  }
  .section-1-container {
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    align-items: center;
  }
  .Campus > span{
    display: none;
  }
  .toptext{
    font-size: 30px;
    font-weight: 500;
    -webkit-text-stroke: 0.5px white;
  }
  .top-image{
    /* height: 60vh; */
    height: auto;
  }
}

/* about section */

.about {
  margin-top: 3rem;
  padding: 0px 10px;
}

.about h2 {
  color: #ff845e;
  font-family:'Poppins';
  font-size: min(2.8vw, 30px);
  font-weight: lighter;
}

.img-about{
  margin-bottom: 10px;
}


.about img {
  /* margin-bottom: 10px; */
  width: 30vw;
  max-width: 500px;
  border-radius: 0.35rem;
  box-shadow: 1px 1px 8px rgba(0,0,0,0.2);

}

.about-container p {
  display: inline-block;
}

.about-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  min-width: auto;
  align-items: center;
  padding: 20px 30px;
}

.about-description p {
  max-width: 550px;
  /* font-size: min(1.8vw, 20px); */
  font-size: 1rem;
}

/*for -you*/

.you-container {
  padding: 0 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr) auto;
  column-gap: 20px;
  row-gap: 20px;
}

.skill:last-child {
  grid-column: 1 / -1; /* span across all columns */
  text-align: center; /* center the content */
}

@media (max-width: 450px) {
  .about {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  .about img {
    width: 70vw;
  }

  .about-container p {
    font-size: 12px;
  }
  h2 {
    font-size: initial !important;
  }

  .main {
    padding: 0px;
  }

  .you-container {
    grid-template-columns: 1fr;
  }
  .about-cntnr1 {
    flex-direction: column-reverse;
  }
  .about h2 {
    text-align: center;
    font-size: initial;
  }
}

.skill-heading {
  color: black;
  font-size: min(3.5vw, 25px);
}

.you-container p {
  /* font-size: min(1.5vw, 20px); */
  font-size: 0.8rem;
}

.skill {
  padding: 15px;
  background-color: white;
  border: 2px solid #ffb49d;
  border-radius: 10px;
  transition: transform 0.2s;

  box-shadow: 0 0 10px #ccc;
}

.skill:hover {
  transform: scale(1.025);
}

.skill-img {
  width: min(4vw, 30px);
  margin-left: 5px;
  margin-left: 5px;
}

/*events section*/

.events-heading {
  color: #ff845e;
  font-size: 3.3vw;
  text-align: center;
}

/* faq */
.faq {
  margin: 4rem 0;
}
