@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

::-webkit-scrollbar{
  display: none;
}


.resources{
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem  3rem;
}

.top{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 2rem 0rem;
}

#search-bar{
  margin-left: auto;
  border-radius: 5em;
  padding: 0 1rem;
  height: 2.5em;
  width: 40em;
  font-size: 1rem;
  border: 2px solid #ddd;
  align-self: center;
}

.heading{
  font-size: 2.5rem;
  margin: 0;
}

button{
  margin-bottom: 2rem;
}

.Button{
  padding: .6em 3em;
  font-size: 1em;
  font-weight: 700;
  border: none;
  border-radius: 1em;
  color: rgb(48, 42, 42);
  background-color: #cfcfcf;
  margin-bottom: 2rem;
}


.soft{
  margin: 0em 1em 1em 0em;
}

#active{
  color: white;
  background-color: #ff845e;
}

.Both{
  height: 100vh;
  overflow-y: scroll;
  padding: 1rem  2.5rem;
}

.software{
  display: flex;
  flex-direction: column;
  margin: 1em 0em 0em 0em;
  gap: 1.5em;
}

.hardware{
  display: flex;
  flex-direction: column;
  margin: 1em 0em 0em 0em;
  gap: 1.5em;
}

.block{
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 2rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05),
    0 4px 4px rgba(0, 0, 0, 0.05), 0 8px 8px rgba(0, 0, 0, 0.05),
    0 16px 16px rgba(0, 0, 0, 0.05);*/
    /* margin: 0.8em 0em 0.8em 0em;  */
  
}

ul{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 1rem;

}

.content {
  margin: 1em;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 1em;
  font-weight: 700;
  color: black;
  margin-bottom: 8px;
}

@media (min-width: 686px) and (max-height: 700px) {
  .resources {
    margin: 2em;
  }
  .Button {
    padding: 0.4em 2em;
    font-size: 0.8em;
    border-radius: 0.3em;
  }

  .block {
    border-radius: 2em;
  }

  .title {
    font-size: 0.8em;
    margin-bottom: 4px;
  }
}

/* media query for mobile display */
@media (max-width: 686px) {

  a{
    font-size: 12px;
  }

  #search-bar{
    max-width: 100%;
  }

  .resources {
    margin: 0;
    padding: 1rem  1.5rem;
  }

  .Both{
    padding: 1rem  .5rem;
  }

  .heading {
    font-size: 1.8em;
  }
  .Button {
    padding: 0.4em 2em;
    font-size: 0.8em;
    border-radius: 0.3em;
  }

  .block {
    border-radius: 2em;
  }

  .title {
    font-size: 1em;
    margin-bottom: 4px;
  }
}
